import React,{useContext} from 'react';
import cl from './Navbar.module.css';
import {ReactComponent as Jobsjsvg} from './jobs.svg';
import {ReactComponent as Tgsvg} from './tg.svg';
import {ReactComponent as Statsvg} from './stat.svg';
import {ReactComponent as Outsvg} from './out.svg';
import { PageApp } from '../../../context/page';
import { memo } from "react";
const Navbar = ({push_job,push_tg}) => {
    const { pageapp,setpageapp } = useContext(PageApp);
    return (
        <div className={cl.mor} >
            <div className={cl.f2}>
                <div onClick={ () => setpageapp('Main')} style={{background: pageapp==='Main' ? '#fff' : '#414141'}}>
                    <Jobsjsvg style={{fill: pageapp==='Main' ? '#414141' : 'white'}}/>
                    {push_job>0?<b className={cl.push}>{push_job}</b>:''}
                </div>
                
                <div onClick={ () => setpageapp('Telegram')} style={{background: pageapp==='Telegram' ? '#fff' : '#414141'}}>
                    <Tgsvg style={{fill: pageapp==='Telegram' ? '#414141' : 'white'}}/>
                    {push_tg>0?<b className={cl.push}>{push_tg}</b>:''}
                </div>
                <div onClick={ () => console.log('load')} style={{background: '#414141',fontSize: 'xx-large',paddingTop:'13px'}}>
                ♻️
                </div>
                <div onClick={ () => setpageapp('Statistic')} style={{background: pageapp==='Statistic' ? '#fff' : '#414141'}}>
                    <Statsvg style={{fill: pageapp==='Statistic' ? '#414141' : 'white'}}/>
                </div>
                <div onClick={ () => {localStorage.removeItem('auth');setpageapp('Login');window.location.reload()}}>
                    <Outsvg/>
                </div>
            </div>
        </div>
    );
};
export default memo(Navbar);
