import React from 'react';
import cl from './Menujob2.module.css';
import Select from '../Input1/Select';
import Input from '../Input1/Input';
import Photo from '../Input1/Photo';
import FinalPriceTable from './FinalPriceTable';

const MainForm = ({ status, sendForm, setAnimation, dphPercent, hookSetDphPercent, hookSetClaclMaterial, hookSetClaclPrace, calcPrace, calcMaterial, doprava, calcPraceState, calcMaterialState }) => {
    return (
        <form className={cl.neon2} onSubmit={sendForm} onInvalid={setAnimation}>
            <div style={{ display: 'flex', gap: "10px", width: '100%' }}>
                <Select key={11} disabled={status==='finished'?true:false} name={'Platební metoda'} req={true} options={['', 'Hotovost', 'Terminal', 'Faktura je pro stále zakazníky', 'Terminal+Hotovost', 'QR']} />
                <Select key={12} disabled={status==='finished'?true:false} name={'DPH %'} req={true} options={['', '0', '12', '21']} sethook={hookSetDphPercent} />
            </div>
            <FinalPriceTable
                disabled={status==='finished'?true:false} 
                calcPrace={calcPrace}
                calcMaterial={calcMaterial}
                doprava={doprava}
                dphPercent={dphPercent}
                calcPraceState={calcPraceState}
                calcMaterialState={calcMaterialState}
                hookSetClaclPrace={hookSetClaclPrace}
                hookSetClaclMaterial={hookSetClaclMaterial}
            />
            <Photo key={'foto'} value={''} disabled={status==='finished'?true:false} name={'FOTO'} type={'file'} />
            <Input key={'comment'} value={''} disabled={status==='finished'?true:false} name={'Comment'} type={'text'} />
            <button id='sendformjob' style={{ opacity: 0 }}>jjj</button>
        </form>
    );
}

export default MainForm;