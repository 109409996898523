import React, { createContext, useState } from 'react';

const PageApp = createContext();

const PageAppProvider = ({ children }) => {
  const [pageapp, setPageapp] = useState(localStorage.getItem('pageMainSave')?localStorage.getItem('pageMainSave'):localStorage.getItem('auth')?'Main':'Login');
  const setpageapp = (data)=>{
    setPageapp(data)
    localStorage.setItem('pageMainSave',data)
  }
  
  return (
    <PageApp.Provider value={{ pageapp, setpageapp }}>
      {children}
    </PageApp.Provider>
  );
};

export { PageApp, PageAppProvider };