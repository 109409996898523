import JobService from './jobService';
import TgService from './tgService';


export default class Sockt{
    static connectjob(version){
        if (this.ws===undefined || this.ws.readyState === WebSocket.CLOSED || this.ws.readyState === WebSocket.CLOSING){
            this.ws = new WebSocket(
                `wss://bobr.instalater24.cz/app/jobs?token=${localStorage.getItem('auth')}&t=${Date.parse(new Date())}&=v${version}`
                );
            this.ws.onmessage =async function(event) {
                if(event.data !== 'ping'){
                    const data= JSON.parse(JSON.parse(event.data).data);
                    switch(data['type']){
                        case 'job':
                            await JobService.add_data(data['data']);
                            break;
                        case 'tg':
                            await TgService.add_tg(data['data'])
                            break;
                        case 'reload':
                            window.location.reload();
                            break;
                        default:
                            console.log(data['data'])
                    }

                }
                  
            };
            this.ws.onclose=()=>{
                setTimeout(function(){Sockt.connectjob(version)}, 1000);
            }
            this.ws.onerror=()=>{
                this.ws.close()
            }
        }

    }
    static chating(){
        if (this.ms===undefined || this.ms.readyState === WebSocket.CLOSED || this.ms.readyState === WebSocket.CLOSING){
            this.ms = new WebSocket(
                `wss://bobr.instalater24.cz/app/ws?token=${localStorage.getItem('auth')}&t=${Date.parse(new Date())}`
                );
            this.ms.onclose=()=>{
                setTimeout(function(){Sockt.chating()}, 1000);
            }
            this.ms.onerror=()=>{
                this.ms.close()
            }
        }


    }
    
    static async send_jobs(message,type="jobs"){
        if (this.ms===undefined || this.ms.readyState === WebSocket.CLOSED || this.ms.readyState === WebSocket.CLOSING || this.ws.readyState === WebSocket.CLOSED || this.ws.readyState === WebSocket.CLOSING){
            if (this.ws===undefined || this.ws.readyState === WebSocket.CLOSED || this.ws.readyState === WebSocket.CLOSING){
                Sockt.connectjob(666)
            }
            if (this.ms===undefined || this.ms.readyState === WebSocket.CLOSED || this.ms.readyState === WebSocket.CLOSING){
                Sockt.chating()
            }
            this.ms.send(JSON.stringify({"type":type,"data":message}))
        }else{
            this.ms.send(JSON.stringify({"type":type,"data":message}))
        }
        
    }
    static async send_material(message){
        if (this.ms===undefined || this.ms.readyState === WebSocket.CLOSED || this.ms.readyState === WebSocket.CLOSING || this.ws.readyState === WebSocket.CLOSED || this.ws.readyState === WebSocket.CLOSING){
            if (this.ws===undefined || this.ws.readyState === WebSocket.CLOSED || this.ws.readyState === WebSocket.CLOSING){
                Sockt.connectjob(666)
            }
            if (this.ms===undefined || this.ms.readyState === WebSocket.CLOSED || this.ms.readyState === WebSocket.CLOSING){
                Sockt.chating()
            }
            this.ms.send(JSON.stringify({"type":"material","data":message}))
        }else{
            this.ms.send(JSON.stringify({"type":"material","data":message}))
        }
        
    }
    static async send_tg(message){
        if (this.ms===undefined || this.ms.readyState === WebSocket.CLOSED || this.ms.readyState === WebSocket.CLOSING || this.ws.readyState === WebSocket.CLOSED || this.ws.readyState === WebSocket.CLOSING){
            if (this.ws===undefined || this.ws.readyState === WebSocket.CLOSED || this.ws.readyState === WebSocket.CLOSING){
                Sockt.connectjob(666)
            }
            if (this.ms===undefined || this.ms.readyState === WebSocket.CLOSED || this.ms.readyState === WebSocket.CLOSING){
                Sockt.chating()
            }
            this.ms.send(JSON.stringify({"type":"tg_save","data":message}))
        }else{
            this.ms.send(JSON.stringify({"type":"tg_save","data":message}))
        }
        
    }
    static async chat_tg(message){
        if (this.ms===undefined || this.ms.readyState === WebSocket.CLOSED || this.ms.readyState === WebSocket.CLOSING || this.ws.readyState === WebSocket.CLOSED || this.ws.readyState === WebSocket.CLOSING){
            if (this.ws===undefined || this.ws.readyState === WebSocket.CLOSED || this.ws.readyState === WebSocket.CLOSING){
                Sockt.connectjob(666)
            }
            if (this.ms===undefined || this.ms.readyState === WebSocket.CLOSED || this.ms.readyState === WebSocket.CLOSING){
                Sockt.chating()
            }
            this.ms.send(JSON.stringify({"type":"tg_chat","data":message}))
        }else{
            this.ms.send(JSON.stringify({"type":"tg_chat","data":message}))
        }
        
    }

    static disconect(){
        try{
            this.ws.onclose=()=>{}
            this.ms.onerror=()=>{}
            this.ws.close()
            this.ms.close()
        }catch{}
        
    }
}