import { set, keys, get ,values, createStore } from 'idb-keyval';


const reportCol = createStore('report', 'col');


export default class ReportService {

    static async write_data(data) {
        const existingData = await get(data.id, reportCol); // Получаем данные из базы по data.id
    
        if (existingData) {
          // Если данные уже существуют, обновляем их
          const updatedData = { ...existingData, ...data };
          await set(data.id, updatedData, reportCol);
        } else {
          // Если данных нет, просто записываем пришедшие данные в базу
          await set(data.id, data, reportCol);
        }
      }
      static async read_data(dataId, value='') {
        const existingData = await get(dataId, reportCol);
    
        if (existingData) {
          return existingData;
        } else {
          return value;
        }
      }

}