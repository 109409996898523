import React from 'react';

const AudioPlayer = (props) => {
    const url = props.url
  return (
    <audio controls>
      <source src={url}  type="audio/mpeg"/>
      Your browser does not support the audio element.
    </audio>
  );
}

export default AudioPlayer;