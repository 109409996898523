import React, { createContext, useEffect, useState,useCallback } from 'react';
import ReportService from '../API/ReportService';

const SelectJob = createContext();

const SelectJobProvider = ({ children }) => {
  const [selectjob, setSelectJob] = useState(null);
  const [calcPrace,setcalcPrace]=useState(0);
  const [calcMaterial,setCalcMaterial]=useState(0);
  const [doprava,setdoprava]=useState(0);
  const [category, setCategory] = useState(null);
  useEffect(()=>{
    if (selectjob!==null){
      ReportService.read_data(selectjob.id,false).then(
        (data)=>{
          if (data.hasOwnProperty("prace")&&parseInt(data['prace'])!==0){
            setcalcPrace(parseInt(data['prace']))
          }else{
            const praceValue=selectjob.custom_fields[0]['value']
            if (praceValue!==null){
              setcalcPrace(parseInt(praceValue))
            }else{
              setcalcPrace(0)
            }
            
          }
          if (data.hasOwnProperty("material")&&parseInt(data['material'])!==0){
            setCalcMaterial(parseInt(data['material']))
          }else{
            const materialValue=selectjob.custom_fields[1]['value']
            if (materialValue!==null){
              setCalcMaterial(parseInt(materialValue))
            }else{
              setCalcMaterial(0)
            }
            
          }
          if (data.hasOwnProperty("doprava")){
            setdoprava(600)
          }else{
            const dopravaValue=selectjob.custom_fields[2]['value']
            if (dopravaValue!==null){
              setdoprava(parseInt(dopravaValue))
            }
            else{
              setdoprava(0)
            }
            
          }
        }
      )
      
    }
  },[selectjob])
  return (
    <SelectJob.Provider value={{ calcPrace,setcalcPrace,selectjob, setSelectJob,calcMaterial,setCalcMaterial,doprava,setdoprava,category, setCategory }}>
      {children}
    </SelectJob.Provider>
  );
};

export { SelectJob, SelectJobProvider };