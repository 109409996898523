import React from 'react';
import AudioPlayer from './AudioPlayer'; // Предположим, что у вас есть компонент AudioPlayer

const AudioPlayerList = ({ jsonData }) => {
  return (
    <div>
      {jsonData.map(record => (
        <AudioPlayer key={record} url={`https://bobr.instalater24.cz/record/${record}.mp3`} />
      ))}
    </div>
  );
};

export default AudioPlayerList;