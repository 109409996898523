import React from 'react';
import AppRouter from "./components/AppRouter";
import Login from "./pages/Login";
import Sockt from "./API/ClassWebSocket";
import './App.css';
import { WorkerProvider } from './context/auth';
import { PageAppProvider } from './context/page';
function App() {
  const version='3.1.45';
  if(localStorage.getItem('auth')){
    Sockt.connectjob(version)
    Sockt.chating()
    return <PageAppProvider><WorkerProvider><AppRouter/></WorkerProvider></PageAppProvider>
  }else{
    try{Sockt.disconect()}
    catch{}
    return <PageAppProvider><WorkerProvider><Login version={version} /></WorkerProvider></PageAppProvider>
  }


  
}


export default App;
