import React,{useState,useContext,useEffect} from 'react';
import { Worker } from '../../../context/auth';
import JobService from '../../../API/jobService';
import cl from './from.module.css';
import {ReactComponent as SVG} from '../t.svg';
import Sockt from '../../../API/ClassWebSocket';
const Form3 = ({setnull,label,setPage}) => {
    const [data, setJobs] = useState([]);
    const { workerid } = useContext(Worker);
  
    const get_order=()=>{
      const ord=JobService.getOrdersByDate(new Date().toISOString().split('T')[0])
      ord.then((data)=>{
        const sorting = data.filter(
                (item) => 
                  item !== undefined && 
                  item.worker.some(worker => worker.id === workerid) && 
                  item.status !== 'cancled' &&
                  item.status !== 'finished'
              );
        setJobs(sorting)
      })
    }
    useEffect(()=>{
      get_order()
    },[])
    const [optionJob, setOptionJob] = useState(null);
    const [optionTime, setOptionTime] = useState(null);
    const lost_time = ['15 minut','30 minut','1 hodina','2 hodina','3 hodina','4 hodina','NESTIHAM dnes']
    function check_date(item){
        if(item.status!=='cancled' && item.start_date && new Date(Date.parse(item.start_date)).toISOString().split('T')[0]===new Date().toISOString().split('T')[0]){
            return <button className={
                optionJob?optionJob === item.id ? cl.selected : cl.none:cl.flex
            } 
            onClick={
               ()=>{if (optionJob!==item.id){
                        setOptionJob(item.id)
                    }else{
                        setOptionJob(null)
                    }}
            }>{item.serial_no} - {item.template.name}<SVG/></button>
        }  
    }
    function check_time(item){
        return <button className={
            optionTime?optionTime === item ? cl.selected : cl.none:cl.flex
            } 
            onClick={
               ()=>{if (optionTime!==item){
                        setOptionTime(item)
                    }else{
                        setOptionTime(null)
                    }}
            }
            >{item}<SVG/></button>  
    }
    const listJobs = data.map((iter) => check_date(iter))
    const listTime = lost_time.map((iter) => check_time(iter))
    async function set_animation(event){
        event.target.scrollIntoView({ block: 'center' });
        const classname=event.target.parentElement.className;
        event.target.parentElement.className=event.target.parentElement.className+' req'
        setTimeout(function(){event.target.parentElement.className=classname},1000)
    }

    return (
    <form className={cl.divselect} onInvalid={set_animation} onSubmit={
            async (event)=>{
                event.preventDefault();
                await Sockt.send_tg({'label':label,'text':`*${label}*\n_${optionTime}_`,'id':parseInt(optionJob)});
                setnull()
                setPage('List');
            }
        }>
        <h2>Vybertre obědnavku</h2>
        <div className={cl.divselect}>
            <input name='job' type='number' className={cl.hiddenselect} required value={optionJob}/>
            {listJobs}
            
        </div>
        <h4>Výberte si čas o kolik máte zpoždění</h4>
        <div className={cl.divselect}>
            <input name='time' type='text' className={cl.hiddenselect} required value={optionTime}/>
            {listTime}
            
        </div>
    </form>
    )
}
export default Form3;
