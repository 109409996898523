import React, { useState,useContext,useEffect } from 'react';
import cl from './Input1.module.css';
import { memo } from 'react';
import ReportService from '../../../API/ReportService';
import { SelectJob } from '../../../context/selectjob';
import Sockt from '../../../API/ClassWebSocket';
import {ReactComponent as ImgSVG} from './svg/img.svg';


const NumInput = ({req=false,disabled=false,value,index=0,sethook=false,name,styles={}}) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const {selectjob}=useContext(SelectJob)
  let classdiv = `${req?cl.inputreq:cl.inputnoreq} ${selectedOption?cl.inputgreen:''} ${disabled?cl.inputdis:''} ${cl.main}`
  useEffect(()=>{
    ReportService.read_data(selectjob.id,value).then((data)=>{
      classdiv=`${req?cl.inputreq:cl.inputnoreq} ${data[name]?cl.inputgreen:''} ${disabled?cl.inputdis:''} ${cl.main}`
      setSelectedOption(data[name])
    })
  },[])
  const handleInputChange = async (event) => {
    const files = event.target.files; // Get the uploaded files array
    const formData = new FormData();
  
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    console.log(formData)
    // Make a POST request to upload the files to the specified URL
    fetch('https://bobr.instalater24.cz/uploadfiles/', {
      method: 'POST',
      body: formData,
      mode: 'no-cors'
    })
      .then(response => console.log(response.json()))
      .then(data => {
        console.log('Files uploaded successfully:', data);
        // Handle the response data as needed
      })
      .catch(error => {
        console.error('Error uploading files:', error);
      });
  
    if (sethook) {
      sethook(event);
    }
  
    // Handle each file individually if needed
    // for (let i = 0; i < files.length; i++) {
    //   const file = files[i];
      
    // }
    // const data = JSON.parse(`{"id":${selectjob.id},"${name}":"${files}","link":"${selectjob.link}"}`);
    // ReportService.write_data(data);
    // Sockt.send_jobs(data, "report");
    // Update UI or state as needed
  };
  
  return (
      <div className={classdiv} style={styles}>
        <label className={`${cl.label} ${cl.focus}`}>{name}</label>
        <ImgSVG className={cl.svglabel}/>
        <input
          type={'file'}
          multiple
          name={name}
          className={cl.value}
          onChange={handleInputChange}
          required={req}
          disabled={disabled}
          data-index={index}
          
        />

      </div>
    
  );
};

export default NumInput;