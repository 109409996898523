import React, { createContext, useState } from 'react';

const Worker = createContext();

const WorkerProvider = ({ children }) => {
  const [workerid, setworker] = useState(localStorage.getItem('auth'));

  return (
    <Worker.Provider value={{ workerid, setworker }}>
      {children}
    </Worker.Provider>
  );
};

export { Worker, WorkerProvider };