import React , { useState, useEffect} from 'react';
import Telegram from "../pages/Telegram/Main";
import Main from "../pages/Job/Main";
import Statistic from "../pages/Stat/Statistic";
import Navbar from "./UI/Navbar/Navbar";
import { memo } from "react";
import { SelectJobProvider } from '../context/selectjob';
import { JobsDateProvider } from '../context/jobsdate';
import Tiger from '../pages/tiger/main';
const AppRouter = (props) => {

        return <>
        {/* {showBeaver && <span role="img" aria-label="beaver" className="spin-animation">🦫</span>} */}
        <Telegram key="telegramm"/>
        <SelectJobProvider>
          <JobsDateProvider>
            <Main/>
          </JobsDateProvider>
          <Statistic key="calculater"/>
        </SelectJobProvider>
        <Tiger/>
        <Navbar push_tg={0} logout={props.logout} push_job={0}/>
      </>
  


};

export default AppRouter;