import React, { createContext, useState } from 'react';

const JobsDate = createContext();

const JobsDateProvider = ({ children }) => {
  const [jobsdate, setJobsdate] = useState(new Date());

  return (
    <JobsDate.Provider value={{ jobsdate, setJobsdate }}>
      {children}
    </JobsDate.Provider>
  );
};

export { JobsDate, JobsDateProvider };