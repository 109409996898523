import React, { useState,useEffect,useContext,useMemo,useCallback } from 'react';
import NavJob from '../../components/UI/NavJob/NavJob';
import cl from './job.module.css';
import Menujob from '../../components/UI/Menujob/Menujob';
import Menujob2 from '../../components/UI/Menujob2/Menujob2';
import Sockt from '../../API/ClassWebSocket';
import JobService from '../../API/jobService';
import { memo } from "react";
import { Worker } from '../../context/auth';
import { SelectJob } from '../../context/selectjob';
import ReportService from '../../API/ReportService';
const JobSelect = () => {
    const { selectjob,setSelectJob,calcPrace,calcMaterial,setcalcPrace,setCalcMaterial } = useContext(SelectJob);
    
    const [data,setData]=useState(selectjob)
    useEffect(()=>{
        JobService.getOrderById(data.id).then((data)=>setData(data))
    },[selectjob,calcPrace,calcMaterial])
    useEffect(()=>{
        if (selectjob!==null){
            ReportService.read_data(selectjob.id,false).then(
              (data)=>{
                if (data.hasOwnProperty("prace")&&parseInt(data['prace'])!==0){
                    setcalcPrace(parseInt(data['prace']))
                }else{
                  const praceValue=selectjob.custom_fields[0]['value']
                  if (praceValue!==null){
                    setcalcPrace(parseInt(praceValue))
                  }
                  
                }
                if (data.hasOwnProperty("material")&&parseInt(data['material'])!==0){
                    setCalcMaterial(parseInt(data['material']))
                }else{
                  const materialValue=selectjob.custom_fields[1]['value']
                  if (materialValue!==null){
                    setCalcMaterial(parseInt(materialValue))
                  }
                  
                }
              }
            )
            
          }
    },[selectjob])
    const { workerid } = useContext(Worker);

    const datetime = useMemo(() => {
        const date_start = data.start_date.split('T')[1].substring(0, 5)
        const date_dur = [
            String(parseInt(data.start_date.split('T')[1].substring(0, 2))+parseInt(data.duration / 60)).padStart(2, '0'),
            String(parseInt(data.start_date.split('T')[1].substring(3, 5))+data.duration % 60).padStart(2, '0')
        ]
        return [`${date_start} - ${date_dur[0]}:${date_dur[1]}`,new Date(Date.parse(data.start_date)).toDateString()]
    },[data.start_date])
    const [menu,setmenu]=useState(data.status);
    const [menugui,setmenugui]=useState(data.status);
    const menu_select = ()=>{
        switch(menugui){
            case 'suspend':
                return <Menujob key={'job1'+data.id+data.status} menugui={menugui} setmenua={setmenu}/>
            case 'public':
                return <Menujob key={'job1'+data.id+data.status} menugui={menugui} setmenua={setmenu}/>
            case 'en_route':
                return <Menujob key={'job1'+data.id+data.status} menugui={menugui} setmenua={setmenu}/>
            case 'started':
                return <Menujob2 key={'job2'+data.id+data.status}/>
            case 'finished':
                return <Menujob2 key={'job2'+data.id+data.status}/>
            default:
                return <Menujob key={'job1'+data.id+data.status} menugui={menugui} setmenua={setmenu}/>
        }
    }
    const menu_select_div=menu_select()


    const hotovo = (event)=>{
        if (menu!=='started'){
            if (data.report_fields.length === 0){
                Sockt.send_jobs({'id':data.id,'status':'finished'})
                // handleClick('Main')
            }else{
                setmenu('started')
            }
            
        }else{
            // setSelectJob(null)
            document.querySelector('#sendformjob').click()
        }
    }
    const worker = data.worker.map((index)=>{ if (index.id!==workerid){return <span>🐅 {index.name}</span>}})
    const setstatus = (event)=>{
        const new_status = {"suspend":"started","public":"en_route","en_route":"started","started":"suspend"}
        event.target.className=event.target.className+' minus-'+new_status[menu]
        setmenu(new_status[menu])
        setmenugui(new_status[menu])
        Sockt.send_jobs({'id':data.id,'status':new_status[menu]})
        if (menu==='public'){
            let dat=data
            dat.status="en_route"
            setData(dat)
            setSelectJob(dat)
            JobService.add_data(data)
        }
    }
    const [status_job,setstatus_job]=useState('zveřejněno')
    const [button,setbutton]=useState(<button className={cl.public} onClick={setstatus}>Vyrazit 📍</button>)
    useEffect(()=>{
        switch(menu){
            case 'suspend':
                setstatus_job('pauza')
                setbutton(<button className={cl.en_route} onClick={setstatus}>Start 📞</button>)
                break;
            case 'public':
                setstatus_job('zveřejněno')
                setbutton(<button className={cl.public} onClick={setstatus}>Vyrazit 📍</button>)
                break;
            case 'en_route':
                setstatus_job('na cestě')
                setbutton(<button className={cl.en_route} onClick={setstatus}>Start 📞</button>)
                break;
            case 'started':
                setstatus_job('zahájeno')
                setbutton(<div className={cl.divb}><button className={cl.pauz} onClick={setstatus} type='button'>⏸️</button><button className={cl.started} onClick={hotovo}>Hotovo</button></div>)
                break;
            case 'finished':
                setstatus_job('dokončeno')
                setbutton(<></>)
                break;
            default:
                setbutton(<button className={cl.public} onClick={setstatus}>Vyrazit 📍</button>)
                setstatus_job(menu)
        }
    },[menu])
    
    return (
        
        <main className={cl.topjob}>
            <NavJob text={'Objednávky'} id={data.serial_no} status={status_job}/>

            <div className={cl.neon}>
                <span className={cl.h2}>
                    Objednavka - {data.template.name}
                </span>
                <span className={cl.span}>{datetime[0]}</span>
                <span className={cl.span}>{datetime[1]}</span>
                {worker}
            </div>
            <div className={cl.divbutton}>
                <div className={['public','en_route','suspend'].indexOf(menugui)!==-1?cl.btn1:cl.btn2} onClick={()=>{if(['started','finished'].indexOf(menu)!==-1){setmenugui('suspend')}}}>Podrobnosti</div>
                <div className={['public','en_route','suspend'].indexOf(menugui)===-1?cl.btn1:cl.btn2} onClick={()=>{if(['started','finished'].indexOf(menu)!==-1){setmenugui('started')}}}>Zprava</div>
            </div>
            {menu_select_div}
            {button}
        </main>
        
    );
};

export default JobSelect;