import React, { useEffect, useState } from 'react';
import cl from './tg.module.css';
import NavDate from '../../components/UI/NavDate/NavDate';
import Tg from '../../components/UI/Job/Tg';
import { memo } from "react";

const List = ({newDate,setDate,page,setPage,listTg,setChat,setPageLocal}) => {
    const date_f=newDate.toISOString().split('T')[0]
    function check_date(tg,index){
        let item={'status':'publictg','id':tg.id,'svg':tg.status,'description':'','read':!tg.read,'start_date':tg.date,'template':{'name':tg.label}}
        try{
            if(new Date(Date.parse(tg.date.replace(' ','T'))).toISOString().split('T')[0]===date_f){
                return <Tg customstyle={{'min-height':'65px'}} key={index} data={item} handleClicks={(e)=>{console.log(e)}} setjobClicks={(e)=>{setChat(tg);setPage('Chat')}}/>
            }
        }catch{
        }
        
        
    }
    const [listdiv,setListdiv]=useState(listTg.map((tg,index)=>check_date(tg,index)))
    useEffect(()=>{
        setListdiv(listTg.map((tg,index)=>check_date(tg,index)))
    },[newDate,page,listTg])
    // <div className={cl.pre}>{tg.id}-{tg.label}-{tg.status}</div>
    // 
    
    return (
    <div className={cl.listmain} style={page==="List"?{transform:"translateX(0)"}:page==="Form"?{transform:"translateX(100%)"}:{transform:"translateX(-100%)"}}>
        <nav className={cl.tgnav}>
                <div className={cl.buttonlist} onClick={setPage}>
                    <svg className={cl.svgbuttonl} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0L10.5 10H0V0Z" fill="#fff"/><path d="M0 0L10.5 10H0V0Z" fill="#fff"/></svg>
                    « Form
                </div>
                <h2>Telegramm</h2>

            </nav>
        <div className={cl.joblist}>
        {listdiv}
        
        </div>
        <NavDate date={newDate} setDate={setDate}/>
    </div>
    );
};

export default List;