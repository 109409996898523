import React,{useMemo,useContext,useEffect,useState} from 'react';
import cl from './Menujob.module.css';
import {ReactComponent as Copysvg} from './svg/copy.svg';
import {ReactComponent as Locsvg} from './svg/loc.svg';
import AudioPlayerList from '../../UI/AudioPlayerList';
import { memo } from "react";
import { SelectJob } from '../../../context/selectjob';
import CallList from './CallList';

const Menujob = (props) => {
    const { selectjob } = useContext(SelectJob);
    function set_menu(){
        if (selectjob.status==='finished'){
            props.setmenua('started')
        }
        if (['started','finished'].indexOf(selectjob.status)){
            try{
                const classname=document.querySelector("#root > main > button").className;
                document.querySelector("#root > main > button").className=document.querySelector("#root > main > button").className+' req'
                setTimeout(function(){try{document.querySelector("#root > main > button").className=classname}catch{}},900)
            }catch{}
        }else {
            props.setmenua('started')
        }
        
    }
    const listItems = useMemo(() => {
        return selectjob.custom_fields.map((number) =>
            <div className={cl.custom}>
                <span>{number.name}</span>
                <span style={{color:"#E26D78",fontWeight: 400}}>{typeof(number.value)==='string' || number.value===null?`${number.value} CZK`:`${number.value.name}`}</span>
            </div>
        );
    },[selectjob.custom_fields]);
    const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};


    const copytext = (event) => {
        event.target.parentElement.children[0].style.fill='#096ba5'
        if (window.isSecureContext && navigator.clipboard) {
          navigator.clipboard.writeText(selectjob.status==='public'?selectjob.address.description:selectjob.address.formatted_address);
        } else {
          unsecuredCopyToClipboard(selectjob.status==='public'?selectjob.address.description:selectjob.address.formatted_address);
        }
        try{setTimeout(()=>{event.target.parentElement.children[0].style.fill='#414141'},500)}
        catch{}
    };
    const ppa=[420228222965,420228222951,420228227409]
    const href_ppa=['tel:+420228222965','tel:+420228222951','tel:+420228227409']
    const [shouldRenderList, setShouldRenderList] = useState(false);
    const [scontacts, setscontacts] = useState([]);
    useEffect(() => {
        if (selectjob.status === 'started') {
            setShouldRenderList(true);
        } else {
            setShouldRenderList(false);
        }
        setscontacts(selectjob.contacts)
    }, [props.key]);

    const listcontacts = scontacts.map((contact) =>
        <>
        <a className={cl.custom} href={href_ppa[scontacts.indexOf(contact)]} style={{height:'40px'}}>
            <span>{contact.name}</span>
            <span style={{color:"#E26D78",fontWeight: 400}}>{ppa[scontacts.indexOf(contact)]}</span>
        </a>
        <AudioPlayerList jsonData={contact.hasOwnProperty('records')?contact.records:[]}/>
        
        </>
    )
    
    let ad2=null;
    if (selectjob.status==='public'){
        ad2=selectjob.address.description
    }else{
        ad2=selectjob.address.formatted_address
    }
    const href_addr=`geo:?q=${ad2}`
    const sc = {'public':1,'en_route':1}
 return(
 <>
    <div className={cl.divm}>
        <div className={cl.neon2}>
            {listItems}
            <p style={{whiteSpace: "pre-line"}}>{selectjob.description}</p>
            <div className={cl.custom} >
            <a className={cl.custom} href={href_addr} style={{width: '80%',height:'40px'}}>
                <span style={{color:"#414141"}}>{selectjob.status==='public'?selectjob.address.description:selectjob.address.formatted_address}</span>
                <span><Locsvg/></span>
            </a>
            <div onClick={copytext} style={{width:'40px',height:'40px'}}><Copysvg /></div>
            </div>
            {sc.hasOwnProperty(props.menugui)?'':listcontacts}
        </div>
    </div>
</>
)  
}
export default Menujob;