import React, { memo, useState, useEffect, useContext } from 'react';
import cl from './Menujob2.module.css';
import Sockt from '../../../API/ClassWebSocket';
import { SelectJob } from '../../../context/selectjob';
import ReportService from '../../../API/ReportService';
import FormButtons from './FormButtons';
import MainForm from './MainForm';
import JobService from '../../../API/jobService';

const Menujob2 = () => {
    const [dphPercent, setDphPercent] = useState(0);
    const { selectjob, calcPrace, calcMaterial, doprava, setSelectJob, setcalcPrace, setCalcMaterial } = useContext(SelectJob);
    // const [calcmaterialstate, setCalcMaterialState] = useState(calcMaterial);
    // const [calcpracestate, setCalcPraceState] = useState(calcPrace);

    useEffect(() => {
        ReportService.read_data(selectjob.id, 0).then((data) => {
            setDphPercent(data['DPH %'])
        });
    }, [selectjob.id]);
    
    const end_form = async () => {
        console.log(calcPrace)
        JobService.update_status({ 'id': selectjob.id, 'status': 'finished' })
        await Sockt.send_jobs({ 'id': selectjob.id, 'status': 'finished' });
        ReportService.read_data(selectjob.id,false).then(
            (data)=>{

                Sockt.send_jobs({
                     'id': selectjob.id,
                     'material':data.hasOwnProperty('MaterialTotal')?data['MaterialTotal']:[],
                     'service':data.hasOwnProperty('ServiceTotal')?{'ServiceTotal':data['ServiceTotal'],'serviceData_K':data.hasOwnProperty('serviceData_K')?data['serviceData_K']:{}}:[],
                     'link': selectjob.link
                    },"report")
                
            }
        )
        setSelectJob(null)
    }

    const send_form = async (event) => {
        event.preventDefault();
        var form = event.target;
        var inputElements = form.querySelectorAll('input');
        var targetInput = null;
        for (var i = 0; i < inputElements.length; i++) {
            var input = inputElements[i];
            if (input.getAttribute('name') === 'Celkem převzato') {
                targetInput = input;
                break;
            }
        }
        var targetInput2 = null;
        for (var z = 0; z < inputElements.length; z++) {
            var input2 = inputElements[z];
            if (input2.getAttribute('name') === 'Comment') {
                targetInput2 = input2;
                break;
            }
        }
        if (targetInput) {
            if (['0', 0, '00', '000'].indexOf(targetInput.value) === -1) {
                await end_form();
            } else {
                if (['', null, undefined].indexOf(targetInput2.value) !== -1) {
                    targetInput2.scrollIntoView({ block: 'center' });
                    targetInput2.parentElement.className = targetInput2.parentElement.className + ' req';
                } else {
                    await end_form();
                }
            }
        } else {
            console.log('finish')
            Sockt.send_jobs({ 'id': selectjob.id, 'status': 'finished' });
            setSelectJob(null);
        }
    }

    const set_animation = async (event) => {
        event.target.scrollIntoView({ block: 'center' });
        const classname = event.target.parentElement.className;
        event.target.parentElement.className = event.target.parentElement.className + ' req';
        setTimeout(function () { event.target.parentElement.className = classname }, 1000);
    }

    const hookSetDphPercent = (event) => {
        setDphPercent(event.target.value ? parseInt(event.target.value) : 0);
    }

    const hookSetClaclMaterial = async (event) => {
        const val = event.target.value !== '' ? parseInt(event.target.value) : 0;
        // setCalcMaterialState(val);
        setCalcMaterial(val);
        const dataC = JSON.parse(`{"id":${selectjob.id},"material":${val}}`);
        await ReportService.write_data(dataC);
    }

    const hookSetClaclPrace = async (event) => {
        const val = event.target.value !== '' ? parseInt(event.target.value) : 0;
        // setCalcPraceState(val);
        setcalcPrace(val);
        const dataC = JSON.parse(`{"id":${selectjob.id},"prace":${val}}`);
        await ReportService.write_data(dataC);
    }

    return (
        <div className={cl.divm}>
            <FormButtons />
            <br />
            <hr />
            <MainForm
                status={selectjob.status}
                sendForm={send_form}
                setAnimation={set_animation}
                dphPercent={dphPercent}
                hookSetDphPercent={hookSetDphPercent}
                hookSetClaclMaterial={hookSetClaclMaterial}
                hookSetClaclPrace={hookSetClaclPrace}
                calcPrace={calcPrace}
                calcMaterial={calcMaterial}
                doprava={doprava}
                calcPraceState={calcPrace}
                calcMaterialState={calcMaterial}
            />
        </div>
    );
}

export default Menujob2;