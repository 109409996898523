
import React,{useState,useEffect,useContext} from 'react';
import { memo } from "react";
import Job from '../../components/UI/Job/Job';
import JobService from '../../API/jobService';
import { Worker } from '../../context/auth';
import { JobsDate } from '../../context/jobsdate';
import { SelectJob

 } from '../../context/selectjob';
const OrderList = () =>  {
  const { selectjob } = useContext(SelectJob);
  const {jobsdate} = useContext(JobsDate)
  const [jobs, setJobs] = useState([]);
  const [buttonFinish,setbuttonFinish]=useState(false)
  const { workerid } = useContext(Worker);
  const [finish, setFinish] = useState('none');
  const openFinishJob = ()=>{
     if (finish === 'none') {
       setFinish('flex');
     } else {
       setFinish('none');
     }
   }
 
  const get_order=()=>{
    const ord=JobService.getOrdersByDate(jobsdate.toISOString().split('T')[0])
    ord.then((data)=>{
      const sorting = data.filter(
              (item) => 
                item !== undefined && 
                item.worker.some(worker => worker.id === workerid) && 
                item.status === 'finished'
            );
      sorting.sort(function(a, b) {
                    const dateA = new Date(a.start_date.replace(' ','T'));
                    const dateB = new Date(b.start_date.replace(' ','T'));
                    return dateA - dateB;
                  })
      setJobs(sorting)
      if(sorting.length>0){
        setbuttonFinish(true)
      }else{
        setbuttonFinish(false)
      }
    })
  }
  useEffect(()=>{
    get_order()
  },[jobsdate,selectjob])
    const order_list=jobs.map((order) => (
        <Job key={order.id} data={order}/>
        // <OrderItem key={order.id} order={order} finish={finish}/>
      ))
    return (<>
        {buttonFinish ? <button className="dokobj" onClick={openFinishJob}>Dokončené objednávky 🏁</button> : ''}
        <div style={{display:finish,flexDirection:'column',gap:'15px'}}>
        {order_list}
        </div>
        </>
    );
  }
export default OrderList;