import React,{useState,useEffect,useMemo,useContext,useCallback} from 'react';
import List from './List';
import Form from './Form';
import Chat from './Chat';
import cl from './tg.module.css';
import TgService from '../../API/tgService';
import { memo } from "react";
import { PageApp } from '../../context/page';
const Telegram = ({set_push_tg=(e)=>{console.log(e)}}) => {
    const { pageapp } = useContext(PageApp);
    const [newDate, setNewDate] = useState(new Date());
    const [chat,setChat]=useState(null)
    function set_chat(event){
        if (event){
            setChat(event)
            TgService.chat_set(setChat,event.id)
        }else{
            setChat(null)
            TgService.chat_set(null)
        }
        
    }

    const [listTg, setListTg] = useState([]);
    const updateOrderById = (orderId, updatedValue) => {
      
        setListTg((prevOrders) => {
        let update_check=true;
        const updatedOrders = prevOrders.map((order) => {
          if (order.id === orderId) {
            update_check=false;
            return updatedValue; 
          }
          return order;
        });
        if (update_check){
          updatedOrders.push(updatedValue)
        }
        return updatedOrders;
      });
    };
    useEffect(() => {
        TgService.get_all_tg().then((data) => {
        setListTg(data);
      });
      TgService.set_updates(updateOrderById)
    }, []); 
    const memoizedTg = useMemo(() => listTg, [listTg]);
    useEffect(() => {
        async function fetchData() {
          memoizedTg.sort(function(a, b) {
            const dateA = new Date(a.date.replace(' ','T'));
            const dateB = new Date(b.date.replace(' ','T'));
            return dateA - dateB;
          })
          const pushCount = memoizedTg.filter(
            (item) => item !== undefined && item.read && new Date()-70000000 < Date.parse(item.date)
          );
          set_push_tg(pushCount.length)
        }
      
        fetchData();
      
      }, [newDate, memoizedTg]);
    const [pagelocal,setPageLocal]=useState(localStorage.getItem('tgPage'))
    const funcSetPage = useCallback(() => {
      switch(pagelocal){
        case 'List':
            setPageLocal('Form')
            localStorage.setItem('tgPage','Form')
            break;
        case 'Form':
            setPageLocal('List')
            localStorage.setItem('tgPage','List')
            setTimeout(function(){
              console.log('reload')
              window.location.reload();
          }, 1500);
            break;
        default:
            console.log('def')
      }
    }, [pagelocal, setPageLocal]);

    function setDa(event) {
        setNewDate(event); 
      }
    
    return (
        <main className={cl.rel} style={pageapp==="Telegram"?{transform:"translateX(0)"}:pageapp==="Main"?{transform:"translateX(100%)"}:{transform:"translateX(-100%)"}}>

            <Form page={pagelocal} setPage={funcSetPage}/>
            <List page={pagelocal} listTg={memoizedTg} setChat={set_chat} setPage={funcSetPage} setPageLocal={setPageLocal} newDate={newDate} setDate={setDa}/>
            {/* <Chat chat={chat} setPage={setPage} setChat={set_chat}/> */}
            
            
        </main>
    );
};

export default Telegram;