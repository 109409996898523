import React, { useState, useEffect, useMemo,useContext } from 'react';
import JobsNavDate from '../../components/UI/NavDate/JobsNavDate';
import JobService from '../../API/jobService';
import '../../Main.css';
import { memo } from "react";
import { Worker } from '../../context/auth';
import { PageApp } from '../../context/page';

const Tiger = () => {

  const { pageapp } = useContext(PageApp);


  return (
    <main style={pageapp === 'Tiger' ? { transform: 'translateY(0)'} : { transform: 'translateY(-100%)' }}>
        
    </main>
  );
};

export default Tiger;
