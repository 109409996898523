import { set, keys,values, get , createStore } from 'idb-keyval';
const tgCol = createStore('tg', 'col');
const tgDate = createStore('tgdate', 'col');
class Semaphore {
  constructor(permits) {
    this.permits = permits;
    this.queue = [];
  }

  async acquire() {
    if (this.permits > 0) {
      this.permits--;
      return Promise.resolve();
    } else {
      return new Promise(resolve => {
        this.queue.push(resolve);
      });
    }
  }

  release() {
    this.permits++;
    const next = this.queue.shift();
    if (next) {
      next();
    }
  }
}
const semaphore = new Semaphore(1);

export default class TgService {
  static async get_all_tg() {
    
    const allTg = [];
    const values_ = await values(tgCol);
    
    for (const data of values_) {
      allTg.push(data)
    }

    allTg.sort(function(a, b) {
      const dateA = new Date(a.date.replace(' ','T'));
      const dateB = new Date(b.date.replace(' ','T'));
      return -(dateA - dateB);
    });

    return allTg;
  }
  static async get_all_push() {
    const allTg = [];
    const keys_ = await keys(tgCol);
    
    for (const key of keys_) {
        const data = await get(key,tgCol);
        if (data.read && new Date(data.date.replace(' ','T'))>new Date(Date.parse(new Date())-1*1000*60*60*24)) {
            allTg.push(data);
        }
    }


    return allTg.length;
  }

  static async get_chat(id) {
    const keys_ = await keys(tgCol);
    let allTg;
    for (const key of keys_) {
        const data = await get(key,tgCol);
        if (data.id === id) {
            allTg =data;
        }
    }


    return allTg;
  }
  static async getOrdersByDate(date) {
    try {
      const existingData = await get(date, tgDate);

      if (!existingData) {
        return [];
      }

      const orders = [];
      for (const orderId of existingData) {
        const order = await get(orderId, tgCol);
        if (order) {
          orders.push(order);
        }
      }

      return orders;
    } catch (error) {
      console.error('Произошла ошибка при получении заказов по дате:', error.message);
      return [];
    }
  }
  static async chat_set(func,id){
    this.chat=func
    this.chat_id=id
  }

    
  static set_updates(func){
    this.updates=func
  }
  static async add_tg(data) {
    try {
      await semaphore.acquire();

      if (!data.date) {
        throw new Error('Ошибка: отсутствует start_date в переданных данных');
      }

      const startDate = data.date.split(' ')[0];
      let existingData = await get(startDate, tgDate);

      if (!existingData) {
        await set(startDate, [data.id], tgDate);
      } else {
        if (!existingData.includes(data.id)) {
          existingData.push(data.id);
          await set(startDate, existingData, tgDate);
        }
      }
      await set(data.id, data, tgCol);
      
    } catch (error) {
      console.error('Произошла ошибка при добавлении данных:', error.message);
    } finally {
      semaphore.release();
    }
  }
  // static async add_tg(data) {
  //   await set(data.id, data,tgCol);
  //   if(this.updates){
  //     this.updates(data.id, data,tgCol)
  //   }
  //   if (this.chat && data.id===this.chat_id){
  //     this.chat(data)
  //   }
  // }

}