import React,{useState} from 'react';
import cl from './tg.module.css';
import {ReactComponent as SVG} from './t.svg';
import Sockt from '../../API/ClassWebSocket';
import Form1 from'./forms/form1';
import Form2 from'./forms/form2';
import Form3 from'./forms/form3';
import Form4 from'./forms/form4';
import Form5 from'./forms/form5';
import { memo } from "react";

const Form = ({data,setPage,page}) => {
    const [activeButton, setActiveButton] = useState(null);
    const [formStep, setFormStep] = useState(null);
    const buttons = [
        { id: 1, label: '🧾 Vystavit fakturu' ,func: (label)=>{
            setFormStep(<Form1 label={label} data={data} setPage={setPage} setnull={()=>{setActiveButton(null);setFormStep(null)}}/>);
        }},

        { id: 5, label: '✅ Prosím další obědnavku' ,func: ()=>{
            Sockt.send_tg({'label':'✅ Prosím další obědnavku','text':'*✅ Prosím další obědnavku*'});setActiveButton(null);setFormStep(null);setPage('List');
        }},

        { id: 7, label: '⏰ Nestiham na zakazku' ,func: (label)=>{
            setFormStep(<Form3 label={label} data={data} setPage={setPage} setnull={()=>{setActiveButton(null);setFormStep(null)}}/>)
        }},
        { id: 8, label: '📦 Material' ,func: (label)=>{
            setFormStep(<Form4 label={label} data={data} setPage={setPage} setnull={()=>{setActiveButton(null);setFormStep(null)}}/>)
        }},

        { id: 13, label: '📋 Technická zpráva' ,func: (label)=>{
            setFormStep(<Form5 label={label} data={data} setPage={setPage} setnull={()=>{setActiveButton(null);setFormStep(null)}}/>)
        }}
      ];
    const handleButtonClick = (button) => {
        setActiveButton((prevState) => (prevState === button.id ? null : button.id));
        if (activeButton!==button.id){
            button.func(button.label)
        }else{
            setFormStep(null)
        }
        
      };
    return (
        <main className={cl.divselect} style={page==="Form"?{transform:"translateX(0)"}:{ transform: "translateX(-100%)"}}>
            <nav className={cl.tgnav}>
                <h2>Telegramm</h2>
                <div className={cl.buttonlist} onClick={setPage}>
                    <svg className={cl.svgbutton} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0L10.5 10H0V0Z" fill="#fff"/><path d="M0 0L10.5 10H0V0Z" fill="#fff"/></svg>
                    List »
                </div>
            </nav>
        {buttons.map((button) => (
                <div
                key={button.id}
                onClick={() => handleButtonClick(button)}
                className={
                    activeButton?activeButton === button.id ? cl.selected : cl.none:cl.flex
                }
                >
                    <SVG/>
                    {button.label}

                </div>
            ))}
        
        {formStep}


        </main>
    );
};

export default memo(Form);