import React,{useContext} from 'react';
import cl from './Menujob2.module.css';
import Input from '../Input1/Input';
import { SelectJob } from '../../../context/selectjob';

const FinalPriceTable = ({ status, calcPrace, calcMaterial, doprava, dphPercent, calcPraceState, calcMaterialState, hookSetClaclPrace, hookSetClaclMaterial,disabled=false }) => {

    return (
        <table className={cl.table}>
            <tr>
                <th colSpan={2} style={{ backgroundColor: "#d9d9d9" }}>Konečna cena</th>
            </tr>
            <tr>
                <td>Práce</td>
                <td className={cl.td}><input className={cl.inputs} onChange={hookSetClaclPrace} value={calcPraceState} disabled={disabled}/></td>
            </tr>
            <tr>
                <td>Máterial</td>
                <td className={cl.td}><input className={cl.inputs} onChange={hookSetClaclMaterial} value={calcMaterialState} disabled={disabled}/></td>
            </tr>
            <tr>
                <td>Doprava</td>
                <td>
                    {doprava}
                </td>
            </tr>
            <tr>
                <td>DPH {dphPercent}%</td>
                <td>{(parseInt(calcPrace) + parseInt(calcMaterial) + parseInt(doprava)) * parseFloat(`0.${dphPercent}`)}</td>
            </tr>
            <tr>
                <td>Celkem:</td>
                <td>{(parseInt(calcPrace) + parseInt(calcMaterial) + parseInt(doprava)) + (parseInt(calcPrace) + parseInt(calcMaterial) + parseInt(doprava)) * parseFloat(`0.${dphPercent}`)}</td>
            </tr>
            <tr>
                <td colSpan={2} style={{ backgroundColor: "#ceefd4", padding: 'unset' }}>
                    <Input key={'celkprevzato'} name={'Celkem převzato'} req={true} type={'number'} styles={{ 'border': 'unset' }} disabled={disabled}/>
                </td>
            </tr>
        </table>
    );
}

export default FinalPriceTable;