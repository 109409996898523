import React,{useContext} from 'react';
import cl from './NavJob.module.css';
import { SelectJob } from '../../../context/selectjob';
import { memo } from 'react';
const NavJob = (props) => {
    const { setSelectJob } = useContext(SelectJob);
    return (
        <nav className={cl.nav}>
            <div className={cl.backdiv} onClick={()=>{ setSelectJob(null)}}>
                <svg width="108" height="47" viewBox="0 0 108 47" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H108V23.5L87 47H0V0Z" fill="#414141"/></svg>
                <div><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.12912 6.84709L10.0944 3.38584C10.6007 3.0924 11.2504 3.44772 11.2504 4.03928V10.9618C11.2504 11.5524 10.6016 11.9087 10.0944 11.6143L4.12912 8.15303C4.01396 8.08729 3.91823 7.99226 3.85165 7.87758C3.78507 7.76291 3.75 7.63266 3.75 7.50006C3.75 7.36746 3.78507 7.23721 3.85165 7.12254C3.91823 7.00786 4.01396 6.91283 4.12912 6.84709Z" fill="white"/>
                    </svg>

                    <span style={{color:'white'}}>{props.text}</span>
                </div>
            </div>
            <div className={cl.center}>
                <span>{props.status}</span>
                <span>{props.id}</span>
            </div>
            <div className={cl.loader}>

            </div>
        </nav>
    );
};

export default memo(NavJob);