import { set, keys, get ,values, createStore } from 'idb-keyval';
const jobsCol = createStore('jobs', 'col');
const jobsDate = createStore('job', 'date');


class Semaphore {
  constructor(permits) {
    this.permits = permits;
    this.queue = [];
  }

  async acquire() {
    if (this.permits > 0) {
      this.permits--;
      return Promise.resolve();
    } else {
      return new Promise(resolve => {
        this.queue.push(resolve);
      });
    }
  }

  release() {
    this.permits++;
    const next = this.queue.shift();
    if (next) {
      next();
    }
  }
}
const semaphore = new Semaphore(1);

export default class JobService {

  static async getOrderById(orderId){
    return await get(orderId,jobsCol)
  }

  static async getOrdersByDate(date) {
    try {
      const existingData = await get(date, jobsDate);

      if (!existingData) {
        return [];
      }

      const orders = [];
      for (const orderId of existingData) {
        const order = await get(orderId, jobsCol);
        if (order) {
          orders.push(order);
        }
      }

      return orders;
    } catch (error) {
      console.error('Произошла ошибка при получении заказов по дате:', error.message);
      return [];
    }
  }


  static async get_all_jobs() {
    const allJobs = [];
    const values_ = await values(jobsCol);
    
    for (const data of values_) {
        if (data.status!=='cancled'){
          allJobs.push(data)
        }
        
    }

    allJobs.sort(function(a, b) {
      const dateA = new Date(a.start_date.replace(' ','T'));
      const dateB = new Date(b.start_date.replace(' ','T'));
      return dateA - dateB;
    });

    return allJobs;
  }

  static async get_all_push(){
    const allJobs = [];
    const keys_ = await keys(jobsCol);
    
    for (const key of keys_) {
        const data = await get(key,jobsCol);
        if (!data.read && ['cancled','finished'].indexOf(data.status)===-1 && new Date(data.start_date.replace(' ','T'))>new Date(Date.parse(new Date())-2*1000*60*60*24)){
          allJobs.push(data)
        }
        
    }


    return allJobs.length
  }


  static set_updates(func){
    this.updates=func
  }
  static async get_job(orderId){
    const getdata =  await get(orderId, jobsCol);
    return getdata
  }
  static async update_status(data){
    const existingData = await get(data.id, jobsCol); 
    
    const updatedData = { ...existingData, ...data };
    await set(data.id, updatedData, jobsCol);
    
  }
  static async add_data(data) {
    try {
      await semaphore.acquire();

      if (!data.start_date) {
        throw new Error('Ошибка: отсутствует start_date в переданных данных');
      }

      const startDate = data.start_date.split('T')[0];
      let existingData = await get(startDate, jobsDate);

      if (!existingData) {
        await set(startDate, [data.id], jobsDate);
      } else {
        if (!existingData.includes(data.id)) {
          existingData.push(data.id);
          await set(startDate, existingData, jobsDate);
        }
      }
      await set(data.id, data, jobsCol);
      
    } catch (error) {
      console.error('Произошла ошибка при добавлении данных:', error.message);
    } finally {
      semaphore.release();
    }
  }


}