import React from 'react';
import cl from './Menujob2.module.css';
import { useContext } from 'react';
import { PageApp } from '../../../context/page';
import { SelectJob } from '../../../context/selectjob';

const FormButtons = () => {
    const { setCategory } = useContext(SelectJob);
    const { setpageapp } = useContext(PageApp);

    return (
        <div className={cl.divbutton} style={{ gap: "10px" }}>
            <div className={cl.btn2} onClick={() => { setpageapp('Statistic'); setCategory('Service') }}>+ Přidat práce</div>
            <div className={cl.btn2} onClick={() => { setpageapp('Statistic'); setCategory('Material') }}>+ Přidat máterial</div>
        </div>
    );
}

export default FormButtons;