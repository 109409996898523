import React, { useState, useContext,useEffect } from 'react';
import cl from './Input1.module.css';
import { memo } from 'react';
import { SelectJob } from '../../../context/selectjob';
import ReportService from '../../../API/ReportService';
import Sockt from '../../../API/ClassWebSocket';
const SelectInput = ({options=['','1','2','3'],req=false,disabled=false,value='',index=0,sethook=false,name,type='text'}) => {
  const {selectjob}=useContext(SelectJob)
  const [selectedOption, setSelectedOption] = useState(value);
  const [classdiv,setDivclass]=useState(`${req?cl.inputreq:cl.inputnoreq} ${selectedOption?cl.inputgreen:''} ${disabled?cl.inputdis:''} ${cl.main}`)
  useEffect(()=>{
    ReportService.read_data(selectjob.id,value).then((data)=>{
      setDivclass(`${req?cl.inputreq:cl.inputnoreq} ${data[name]?cl.inputgreen:''} ${disabled?cl.inputdis:''} ${cl.main}`)
      setSelectedOption(data[name])
    })
    
  },[])
  const handleInputChange = (event) => {
    if (sethook){
      sethook(event)
    }
    const data=JSON.parse(`{"id":${selectjob.id},"${name}":"${event.target.value}","link":"${selectjob.link}"}`)
    ReportService.write_data(data)
    if (event.target.value!==''){
      Sockt.send_jobs(data,"report")
    }
    setSelectedOption(event.target.value);
    setDivclass(`${req?cl.inputreq:cl.inputnoreq} ${cl.inputgreen} ${disabled?cl.inputdis:''} ${cl.main}`)
  };
  
  const dataoptions=options.map((option)=><option value={option}>{option}</option>)
  return (
      <div className={classdiv}>
        <label className={`${cl.label} ${cl.focus}`}>{name}</label>
        <select
          type={type}
          className={cl.value}
          name={name}
          value={selectedOption}
          onChange={handleInputChange}
          required={req}
          disabled={disabled}
          data-index={index}
          data-value={selectedOption}
          style={{width:'100%'}}
        >
            {dataoptions}
        </select>

      </div>
    
  );
};

export default memo(SelectInput);