import React,{useState,useContext,useEffect,useMemo} from 'react';
import cl from './from.module.css';
import {ReactComponent as SVG} from '../t.svg';
import Sockt from '../../../API/ClassWebSocket';
import InputTG from '../../../components/UI/Input1/InputTg';
import { Worker } from '../../../context/auth';
import JobService from '../../../API/jobService';

const Form1 = ({setnull,label,setPage}) => {
    const [data, setJobs] = useState([]);
    const { workerid } = useContext(Worker);
  
    const get_order=()=>{
      const ord=JobService.getOrdersByDate(new Date().toISOString().split('T')[0])
      ord.then((data)=>{
        const sorting = data.filter(
                (item) => 
                  item !== undefined && 
                  item.worker.some(worker => worker.id === workerid) && 
                  item.status !== 'cancled'
              );
        setJobs(sorting)
      })
    }
    useEffect(()=>{
      get_order()
    },[])
    let jjj;
    if (localStorage.getItem('ft1j')){
        jjj=parseInt(localStorage.getItem('ft1j'))
    }
    let mtp;
    if (localStorage.getItem('ft7')){
        mtp=localStorage.getItem('ft7')
    }else{
        mtp='💵 Hotovost'
    }
    const [optionJob, setOptionJob] = useState(null);
    const [method, setMethod] = useState(mtp);
    const [name, setName] = useState(localStorage.getItem('ft1'));
    const [ico, setIco] = useState(localStorage.getItem('ft2'));
    const [email, setEmail] = useState(localStorage.getItem('ft3'));
    const [price, setPrice] = useState(localStorage.getItem('ft4'));
    const [address, setAddress] = useState(localStorage.getItem('ft5'));
    const [popis, setPopis] = useState(localStorage.getItem('ft6'));
    const [form, setForm] = useState('Fizicka');
    function check_date(item){
        return <div className={
            optionJob?optionJob === item.id ? cl.selected : cl.none:cl.flex
        } 
        onClick={
           ()=>{if (optionJob!==item.id){
                    localStorage.setItem('ft1j',item.id)
                    setOptionJob(item.id)
                }else{
                    localStorage.setItem('ft1j',null)
                    setOptionJob(null)
                }}
        }
        id={item.id} >{item.serial_no} - {item.template.name}<SVG/></div>
        
    }
    const listJobs = data.map((iter,index) => check_date(iter))
    async function set_animation(event){
        event.target.scrollIntoView({ block: 'center' });
        const classname=event.target.parentElement.className;
        event.target.parentElement.className=event.target.parentElement.className+' req'
        setTimeout(function(){event.target.parentElement.className=classname},1000)
    }

    return (
    <form className={cl.divselect} onInvalid={set_animation} onSubmit={
            async (event)=>{
                
                event.preventDefault();
                let text;
                if (form==='Fizicka'){
                    text=`*${label}*\n*Jmeno Přijmení:* ${name}\n*Email:* ${email}\n*Adresa:* ${address}\n*Castka:* ${price}\n*Popis:* ${popis}\n${method}`
                }else{
                    text=`*${label}*\n*Ičo:* ${ico}\n*Email:* ${email}\n*Castka:* ${price}\n*Popis:* ${popis}\n${method}`
                }
                await Sockt.send_tg({'label':label,'text':text,'id':parseInt(optionJob)});
                localStorage.removeItem('ft1')
                localStorage.removeItem('ft2')
                localStorage.removeItem('ft3')
                localStorage.removeItem('ft4')
                localStorage.removeItem('ft5')
                localStorage.removeItem('ft6')
                localStorage.setItem('ft7','💵 Hotovost')
                setnull()
                setPage('List');
            }
        }>
        <h2>Vybertre obědnavku</h2>
            <input type='number' className={cl.hiddenselect} required value={optionJob}/>
            {listJobs}
            <InputTG name={'Osoba'} value={form} type={'select'} setvalue={setForm} req={true} select_list={['Fizicka','Pravnicka']}/>
            {
                form==='Fizicka'
                ?<>
                <InputTG name={'Jmeno Přijmení'} value={name} type={'numbtexter'} setvalue={(e)=>{localStorage.setItem('ft1',e);setName(e)}} req={true}/>
                <InputTG name={'Email'} value={email} type={'email'} setvalue={(e)=>{localStorage.setItem('ft3',e);setEmail(e)}} req={true}/>
                <InputTG name={'Adresa'} value={address} type={'text'} setvalue={(e)=>{localStorage.setItem('ft5',e);setAddress(e)}} req={true}/>
                <InputTG name={'častka'} value={price} type={'number'} setvalue={(e)=>{localStorage.setItem('ft4',e);setPrice(e)}} req={true}/>
                <InputTG name={'popis'} value={popis} type={'text'} setvalue={(e)=>{localStorage.setItem('ft6',e);setPopis(e)}} req={true}/>
                <InputTG name={'Platební metoda'} value={method} type={'select'} setvalue={(e)=>{localStorage.setItem('ft7',e);setMethod(e)}} req={true} select_list={['💵 Hotovost','💳 Karta','🏦 Převodem',"QR"]}/>
                </>
                :
                <>
                <InputTG name={'Ičo'} value={ico} type={'number'} setvalue={(e)=>{localStorage.setItem('ft2',e);setIco(e)}} req={true}/>
                <InputTG name={'Email'} value={email} type={'email'} setvalue={(e)=>{localStorage.setItem('ft3',e);setEmail(e)}} req={true}/>
                <InputTG name={'častka'} value={price} type={'number'} setvalue={(e)=>{localStorage.setItem('ft4',e);setPrice(e)}} req={true}/>
                <InputTG name={'popis'} value={popis} type={'text'} setvalue={(e)=>{localStorage.setItem('ft6',e);setPopis(e)}} req={true}/>
                <InputTG name={'Platební metoda'} value={method} type={'select'} setvalue={(e)=>{localStorage.setItem('ft7',e);setMethod(e)}} req={true} select_list={['💵 Hotovost','💳 Karta','🏦 Převodem',"QR"]}/>
                </>
            }
            
            
        <button className={cl.flex}>Odeslat<SVG/></button>
    </form>
    )
}
export default Form1;