import React, {createContext, useContext, useState } from 'react';
import PostService from "../API/PostService";
import { Worker } from '../context/auth';
import { PageApp } from '../context/page';
const FormContext = createContext(1);


export default function Login({version}) {
  const { setpageapp } = useContext(PageApp);
  const [form, setFrorm] = useState(1);
  return (
    <FormContext.Provider value={form}>
      <Form handleClick={setpageapp} version={version} setFrorm={setFrorm}/>
    </FormContext.Provider>
  )
}

function Form({handleClick,version,setFrorm}) {
    
    localStorage.setItem('tgPage','Form')
    const { setworker } = useContext(Worker);
    const form = useContext(FormContext);
    const send_sms_code = event =>{
        event.preventDefault();
        
        const response = PostService.sendCode(phone_input);
        console.log(response.then((data)=>{
            if (data.data.message==='ok'){
                setFrorm(form === 2 ? 1 : 2);
            }else{
                alert(data.data.message)
            }
        }))

    }
    const get_uuid = event =>{
        event.preventDefault();
        const response = PostService.getUUID(code_input);
        console.log(response.then((data)=>{
            if ('uuid' in data.data){
                localStorage.setItem('auth',data.data.uuid);
                setworker(data.data.uuid)
                handleClick('Main');
                window.location.reload()
            }else{
                alert(data.data.message)
            }
        }))

    }
    const [phone_input, setPhoneinput] = useState(420)
    const [code_input, setCodeinput] = useState('')
    switch(form){
        case 1:
            return <form style={{height: '100vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap:'15px',
                alignItems: 'center',}} onSubmit={send_sms_code}>
                <h1>Phone</h1>
                <input type='tel' maxLength={12} value={phone_input} style={{'width': 'calc(70% - 30px)','padding':'15px','font-weight':'500',background:'#00000000'}} 
                onChange={e => setPhoneinput(e.target.value)}></input>
                <button 
                    style={{border: 'unset',
                    backgroundColor: 'rgb(45 212 191)',
                    padding: '15px',
                    width:' 70%',
                    color: '#000000',
                    fontSize: '20px',
                    fontWeight: 700}}>
                    send SMS code
                </button>
                <h1 onClick={()=> {
  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
  }
}}>{version}</h1>
            </form>
            
        case 2:
            return <form style={{height: '100vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap:'15px',
                alignItems: 'center',}} onSubmit={get_uuid}>
                <h1>SMS CODE</h1>
                <input required value={code_input} style={{'width': 'calc(70% - 30px)','padding':'15px','font-weight':'500',background:'#00000000'}} 
                onChange={e => setCodeinput(e.target.value)}></input>
                <button 
                    style={{border: 'unset',
                    backgroundColor: 'rgb(45 212 191)',
                    padding: '15px',
                    width:' 70%',
                    color: '#000000',
                    fontSize: '20px',
                    fontWeight: 700}}>
                    Login
                </button>
            </form>
        default:
            console.log('def')
    }
}