import React,{useState,useEffect} from 'react';
import cl from './Input1.module.css';
import {ReactComponent as ImgSVG} from './svg/img.svg';
import {ReactComponent as SelectSVG} from './svg/select.svg';


export default function InputTG(props){
const [value,setInputValue]=useState(props.value);

async function change(event){
    if (props.type === 'tel' || props.type === 'number'){
        setInputValue(event.target.value.replace(/\D/g, ''))
    }else{
        setInputValue(event.target.value)
    }
    
}
async function select_change(event){
    if (props.type === 'tel' || props.type === 'number'){
        setInputValue(event.target.value.replace(/\D/g, ''))
    }else{
        setInputValue(event.target.value)
    }
    await send_value(event)
}
const [labelclass,setLabelClass]=useState(`${cl.label} ${props.value?cl.focus:''}`);
const classdiv=`${props.req?cl.inputreq:cl.inputnoreq} ${([null,'',undefined].indexOf(props.value)===-1)?cl.inputgreen:''} ${cl.main}`
async function setfocus(item){
    setLabelClass(`${cl.label} ${cl.focus}`)
    
}



async function send_value(event){
    if (event.target.value===''){
        setLabelClass(cl.label)
    }else{
        props.setvalue(event.target.value)
    }
}


function select(arr){
    const options = arr.map((option) =>
    <option className={cl.option}>{option}</option>
  );
    return (<select onChange={select_change} onFocus={setfocus} className={cl.select}>{options}</select>)
}
useEffect(() => {
    setInputValue(props.value);
    if(props.value){
        setLabelClass(`${cl.label} ${cl.focus}`)
    }else{
        setLabelClass(`${cl.label}`)
    }
  }, [props.value]);
 return(
 <div className={classdiv}>
   
    <label className={labelclass}>{props.name}</label>
    <div>{props.type==='file'?<ImgSVG className={cl.svglabel}/>:''}{props.type==='select'?<SelectSVG className={cl.svglabel}/>:''}</div>
    {['file','date'].indexOf(props.type)!==-1?<p className={cl.value2}>{value}</p>:''}
    <input type={props.type} style={props.type==='file'?{opacity:0}:{}} name={props.name} className={cl.value} value={props.type==='file'?'':value || ''} onChange={change} onFocus={setfocus} onBlur={send_value} required={props.type!=='file'?props.req:props.value===''?true:false} ></input>
    {props.type==='select'?select(props.select_list):''}
</div>)   
}
// export default Input1;