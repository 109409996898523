import React,{useContext, useEffect, useState} from 'react';
import './Job.css';
import {ReactComponent as SVGpublic} from './svg/public.svg';
import {ReactComponent as SVGenroute} from './svg/en_route.svg';
import {ReactComponent as SVGstarted} from './svg/started.svg';
import {ReactComponent as SVGsuspend} from './svg/suspend.svg';
import {ReactComponent as SVGfinished} from './svg/finished.svg';
import { memo } from "react";
import JobService from '../../../API/jobService';
import Sockt from '../../../API/ClassWebSocket';
const Tg = ({data,finish}) => {
    const [job,setJob]=useState(data)
    const classes = `job ${job.status}`
    const date_start = job.start_date.split(' ')[1].substring(0, 5)
    // const date_dur = [
    //     String(parseInt(job.date.split(' ')[1].substring(0, 2))+parseInt(job.duration / 60)).padStart(2, '0'),
    //     String(parseInt(job.date.split(' ')[1].substring(3, 5))+job.duration % 60).padStart(2, '0')
    // ]
    const date = `${date_start}`
    // const date = ''
    
    function set_svg(){
        switch(job.status){
            case 'public':
                return <SVGpublic/>
            case 'publictg':
                return ''
            case 'en_route':
                return <SVGenroute/>
            case 'started':
                return <SVGstarted/>
            case 'suspend':
                return <SVGsuspend/>
            case 'finished':
                return <SVGfinished/>
            default:
                return ''
        }
    }
    function setjob(){
        console.log(data)
    }
    
    return (
        <div className={classes} onClick={setjob} style={finish?{display:finish}:{}}>
            {set_svg()}
            <span className='s1'>{date}</span>
            {job.read===false?<span className='push'></span>:''}
            <span className='id'>{job.id}</span>
            <span className='s2'>{job.template.name}</span>
            <p>{job.description}</p>
            
        </div>
    );
};

export default Tg;