import React,{useState,useContext,useEffect} from 'react';
import { Worker } from '../../../context/auth';
import JobService from '../../../API/jobService';
import cl from './from.module.css';
import {ReactComponent as SVG} from '../t.svg';
import Sockt from '../../../API/ClassWebSocket';
import InputTG from '../../../components/UI/Input1/InputTg';
import PhotoMaterial from '../../../components/UI/Input1/PhotoMaterial';
const Form4 = ({setnull,label,setPage}) => {
    const [data, setJobs] = useState([]);
    const { workerid } = useContext(Worker);
  
    const [optionJob, setOptionJob] = useState(null);
    const [photos, setPhotos] = useState('');
    const addPhoto = (newPhoto) => {
      setPhotos((prevPhotos) => prevPhotos ? `${prevPhotos}\n${newPhoto}` : newPhoto);
    };
    const get_order=()=>{
      const ord=JobService.getOrdersByDate(new Date().toISOString().split('T')[0])
      ord.then((data)=>{
        const sorting = data.filter(
                (item) => 
                  item !== undefined && 
                  item.worker.some(worker => worker.id === workerid) && 
                  item.status !== 'cancled' &&
                  item.status !== 'finished'
              );
        setJobs(sorting)
      })
    }
    useEffect(()=>{
      get_order()
    },[])
    const [material, setMaterial] = useState(null);
    const [kolik, setKolik] = useState(null);

    async function set_animation(event){
        event.target.scrollIntoView({ block: 'center' });
        const classname=event.target.parentElement.className;
        event.target.parentElement.className=event.target.parentElement.className+' req'
        setTimeout(function(){event.target.parentElement.className=classname},1000)
    }
    function check_date(item){
      if(item.status!=='cancled' && item.start_date && new Date(Date.parse(item.start_date)).toISOString().split('T')[0]===new Date().toISOString().split('T')[0]){
          return <button className={
              optionJob?optionJob === item.id ? cl.selected : cl.none:cl.flex
          } 
          onClick={
             ()=>{if (optionJob!==item.id){
                      setOptionJob(item.id)
                  }else{
                      setOptionJob(null)
                  }}
          }
          id={item.id} >{item.serial_no} - {item.template.name}<SVG/></button>
      }
      
  }
  const listJobs = data.map((iter) => check_date(iter))
    return (
    <form className={cl.divselect} onInvalid={set_animation} onSubmit={
            async (event)=>{
                event.preventDefault();
                await Sockt.send_tg({'label':label,'text':`*${label}*\n*materiál*: ${material}\n*Kolik ks*: ${kolik}\n*id:* ${optionJob}\n*PHOTO:* ${photos}`,'id':parseInt(optionJob)});
                setnull()
                setPage('List');
            }
        }>
          <h2>Vybertre obědnavku !</h2>
          {listJobs}
        <p>Napište sem odkaz na materiál který chcete objednat Nebo přesnou specifikace</p>
        <InputTG name={'Materiál'} value={material} type={'text'} setvalue={setMaterial} req={true}/>
        <InputTG name={'Kolik ks ?'} value={kolik} type={'number'} setvalue={setKolik} req={true}/>
        <p>Photo</p>
        <PhotoMaterial setPhotos={addPhoto}/>
        <button className={cl.flex}>Odeslat<SVG/></button>
    </form>
    )
}
export default Form4;
