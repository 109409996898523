import React,{useState,useContext,useEffect,useMemo} from 'react';
import cl from './from.module.css';
import {ReactComponent as SVG} from '../t.svg';
import Sockt from '../../../API/ClassWebSocket';
import InputTG from '../../../components/UI/Input1/InputTg';
import { Worker } from '../../../context/auth';
import JobService from '../../../API/jobService';

const Form4 = ({setnull,label,setPage}) => {
    const [data, setJobs] = useState([]);
    const { workerid } = useContext(Worker);
  
    const get_order=()=>{
      const ord=JobService.getOrdersByDate(new Date().toISOString().split('T')[0])
      ord.then((data)=>{
        const sorting = data.filter(
                (item) => 
                  item !== undefined && 
                  item.worker.some(worker => worker.id === workerid) && 
                  item.status !== 'cancled'
              );
        setJobs(sorting)
      })
    }
    useEffect(()=>{
      get_order()
    },[])
    let jjj;
    if (localStorage.getItem('ft1j')){
        jjj=parseInt(localStorage.getItem('ft1j'))
    }
    let mtp;
    if (localStorage.getItem('ft7')){
        mtp=localStorage.getItem('ft7')
    }else{
        mtp='💵 Hotovost'
    }
    const [optionJob, setOptionJob] = useState(null);
    const [email, setEmail] = useState(localStorage.getItem('fz1'));
    const [popis1, setPopis1] = useState(localStorage.getItem('fz2'));
    const [popis2, setPopis2] = useState(localStorage.getItem('fz3'));
    const [popis3, setPopis3] = useState(localStorage.getItem('fz4'));
    function check_date(item){
        return <div className={
            optionJob?optionJob === item.id ? cl.selected : cl.none:cl.flex
        } 
        onClick={
           ()=>{if (optionJob!==item.id){
                    localStorage.setItem('ft1j',item.id)
                    setOptionJob(item.id)
                }else{
                    localStorage.setItem('ft1j',null)
                    setOptionJob(null)
                }}
        }
        id={item.id} >{item.serial_no} - {item.template.name}<SVG/></div>
        
    }
    const listJobs = data.map((iter,index) => check_date(iter))
    async function set_animation(event){
        event.target.scrollIntoView({ block: 'center' });
        const classname=event.target.parentElement.className;
        event.target.parentElement.className=event.target.parentElement.className+' req'
        setTimeout(function(){event.target.parentElement.className=classname},1000)
    }

    return (
    <form className={cl.divselect} onInvalid={set_animation} onSubmit={
            async (event)=>{
                
                event.preventDefault();
                let text;
                text=`*${label}*\n*Email:* ${email}\n*Bylo zjištěno:* ${popis1}\n*Provedené opravy:* ${popis2}\n*Doporučení od instalatéra:* ${popis3}\n`

                await Sockt.send_tg({'label':label,'text':text,'id':parseInt(optionJob)});
                localStorage.removeItem('ft1')
                localStorage.removeItem('ft2')
                localStorage.removeItem('ft3')
                localStorage.removeItem('ft4')
                localStorage.removeItem('ft5')
                localStorage.removeItem('ft6')
                localStorage.setItem('ft7','💵 Hotovost')
                setnull()
                setPage('List');
            }
        }>
        <h2>Vybertre obědnavku</h2>
            <input type='number' className={cl.hiddenselect} required value={optionJob}/>
            {listJobs}

            <InputTG name={'Email'} value={email} type={'email'} setvalue={(e)=>{localStorage.setItem('fz1',e);setEmail(e)}} req={true}/>
            <InputTG name={'Bylo zjištěno:'} value={popis1} type={'text'} setvalue={(e)=>{localStorage.setItem('fz2',e);setPopis1(e)}} req={true}/>
            <InputTG name={'Provedené opravy:'} value={popis2} type={'text'} setvalue={(e)=>{localStorage.setItem('fz3',e);setPopis2(e)}} req={true}/>
            <InputTG name={'Doporučení od instalatéra:'} value={popis3} type={'text'} setvalue={(e)=>{localStorage.setItem('fz4',e);setPopis3(e)}} req={true}/>

            
            
        <button className={cl.flex}>Odeslat<SVG/></button>
    </form>
    )
}
export default Form4;