import React, { useState, useEffect, useMemo,useContext } from 'react';
import JobsNavDate from '../../components/UI/NavDate/JobsNavDate';
import JobService from '../../API/jobService';
import '../../Main.css';
import JobSelect from './JobSelect';
import OrderList from './OrderList';
import OrderFinish from './OrderFinish.jsx';
import { memo } from "react";
import { Worker } from '../../context/auth';
import { SelectJob } from '../../context/selectjob';
import { PageApp } from '../../context/page';
import { JobsDate } from '../../context/jobsdate';

const Main = ({ set_push_job, setJobsTg}) => {
  const {jobsdate} = useContext(JobsDate)
  const [finish, setFinish] = useState('none');
  const { selectjob } = useContext(SelectJob);
  const { pageapp } = useContext(PageApp);

  const openFinishJob = ()=>{
    if (finish === 'none') {
      setFinish('flex');
    } else {
      setFinish('none');
    }
  }

  const memoizedJobTry= useMemo(() => {
    if(selectjob!==null){
      return <JobSelect/>
    }
      
  }, [selectjob]);

  return (
    <main style={pageapp === 'Main' ? { transform: 'translateX(0)' } : { transform: 'translateX(-100%)' }}>
      {memoizedJobTry}
      <div className="jobs-list">
        <div className="maindate">
          <h2>Objednávky</h2>
          <span style={{ paddingRight: '15px', color: '#846FD5', fontWeight: 600 }}>
            {jobsdate.toISOString().split('T')[0]}
          </span>
        </div>
        
        <OrderList key="mainjob" />
        <OrderFinish key="finishjob" />
        {/* {buttonFinish ? <button className="dokobj" onClick={openFinishJob}>Dokončené objednávky 🏁</button> : ''} */}
        {/* {memoizedOrderListFinish} */}
        <JobsNavDate/>
      </div>
    </main>
  );
};

export default Main;
