import React, { useState,useContext,useEffect } from 'react';
import cl from './Input1.module.css';
import { memo } from 'react';
import ReportService from '../../../API/ReportService';
import { SelectJob } from '../../../context/selectjob';
import Sockt from '../../../API/ClassWebSocket';


const NumInput = ({req=false,disabled=false,value,index=0,sethook=false,name,type='text',styles={}}) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const {selectjob}=useContext(SelectJob)
  let classdiv = `${req?cl.inputreq:cl.inputnoreq} ${selectedOption?cl.inputgreen:''} ${disabled?cl.inputdis:''} ${cl.main}`
  useEffect(()=>{
    ReportService.read_data(selectjob.id,value).then((data)=>{
      classdiv=`${req?cl.inputreq:cl.inputnoreq} ${data[name]?cl.inputgreen:''} ${disabled?cl.inputdis:''} ${cl.main}`
      setSelectedOption(data[name])
    })
  },[])
  const handleInputChange = (event) => {
    let val;
    if (sethook){
      sethook(event)
    }
    if (type==='number'){
      val=parseInt(event.target.value)
    }else{
      val=event.target.value
    }
    const data=JSON.parse(`{"id":${selectjob.id},"${name}":"${val!==''?val:0}","link":"${selectjob.link}"}`)
    ReportService.write_data(data)
    if (event.target.value!==''){
      Sockt.send_jobs(data,"report")
    }
    setSelectedOption(event.target.value);
    classdiv=`${req?cl.inputreq:cl.inputnoreq} ${cl.inputgreen} ${disabled?cl.inputdis:''} ${cl.main} ${cl.main}`
  };
  return (
      <div className={classdiv} style={styles}>
        <label className={`${cl.label} ${cl.focus}`}>{name}</label>
        <input
          type={type}
          name={name}
          className={cl.value}
          value={selectedOption}
          onChange={handleInputChange}
          required={req}
          disabled={disabled}
          data-index={index}
          data-value={selectedOption}
          
        />

      </div>
    
  );
};

export default NumInput;