import React, { useState } from 'react';
import cl from './Input1.module.css';
import {ReactComponent as ImgSVG} from './svg/img.svg';


const PhotoMaterial = ({req=false,disabled=false,value,index=0,sethook=false,name,styles={},setPhotos}) => {
  const [selectedOption, setSelectedOption] = useState(value);
  let classdiv = `${req?cl.inputreq:cl.inputnoreq} ${selectedOption?cl.inputgreen:''} ${disabled?cl.inputdis:''} ${cl.main}`
  function generateImageLinks(data) {
    try {
        const imageNames = data.imageNames || [];
        const baseUrl = "https://bobr.instalater24.cz/static/";
        const links = imageNames.map(imageName => baseUrl + imageName);
        const result = links.join('\n');
        return result;
    } catch (error) {
        console.error("Ошибка: некорректный JSON.", error);
        return null;
    }
  }
  const socket = new WebSocket('wss://bobr.instalater24.cz/upload/ws');

  socket.onopen = function() {
      console.log('WebSocket соединение установлено');
  };

  socket.onmessage = function(event) {
    setPhotos(generateImageLinks(JSON.parse(event.data)))
  };

  const handleInputChange = async (event) => {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const reader = new FileReader();

          reader.onload = function(e) {
              const arrayBuffer = e.target.result;
              socket.send(arrayBuffer);
              console.log(`Файл ${file.name} отправлен`);
          };

          reader.readAsArrayBuffer(file);
      }
  };
  // const handleInputChange = async (event) => {
  //   const files = event.target.files;
  //   const formData = new FormData();
  //   for (let i = 0; i < files.length; i++) {
  //     formData.append('files', files[i]);
  //   }
  //   console.log(formData)
  //   fetch(`https://bobr.instalater24.cz/uploadfilesmaterial/?time=${Date.parse(new Date())}`, {
  //     method: 'POST',
  //     body: formData,
  //     // mode: 'no-cors'
  //   }).then(response => {
  //     return response.json(); 
  //   })
  //   .then(data => {
  //     console.log('Files uploaded successfully:', data);
  //     setPhotos(generateImageLinks(data))
  //   })
  //   .catch(error => {
  //     console.error('Error uploading files:', error);
  //   });
  
  //   if (sethook) {
  //     sethook(event);
  //   }
  // };
  return (
      <div className={classdiv} style={styles}>
        <label className={`${cl.label} ${cl.focus}`}>{name}</label>
        <ImgSVG className={cl.svglabel}/>
        <input
          type={'file'}
          multiple
          name={name}
          className={cl.value}
          onChange={handleInputChange}
          required={req}
          disabled={disabled}
          data-index={index}
        />
      </div>
  );
};
export default PhotoMaterial;