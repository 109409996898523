
import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import { StrictMode } from 'react';

const container = document.getElementById('root');
const root = createRoot(container); 

root.render(<StrictMode><App/></StrictMode>);


reportWebVitals();