import axios from "axios";

export default class PostService {
    static async set_status(stat) {
        const response = await axios.get('https://api.instalater24.cz/app/set_status', {
            params: {
                token: localStorage.getItem('auth'),
                status: stat
            }
        })
        return response;
    }

    static async getUUID(code) {
        const response = await axios.get('https://api.instalater24.cz/app/get_uuid/' + code + '/' + Date.parse(new Date()))
        return response;
    }

    static async sendCode(phone) {
        var formData = new FormData();
        formData.set("phone",phone)
        const response = await axios.post('https://api.instalater24.cz/app/send_code/'+Date.parse(new Date()),formData, {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        return response;
    }
}